import { SetThemeContext } from "@matix/theme";
import React from "react";
import SEO from "../components/seo/seo";
import Layout from "../components/layout";
import RenderMdPage from "../config/renderMdPage";
import { isSSR } from "../components/utils";

export default props => {
   const { pageContext } = props;
   if (!pageContext.compData || isSSR()) return <></>;
   window.pageData = { ...props.location };
   SetThemeContext(pageContext.compData.frontmatter.theme);

   return (
      <>
         <SEO />
         <Layout>
            <RenderMdPage {...pageContext.compData} {...props}></RenderMdPage>
         </Layout>
      </>
   );
};
